import jwt_decode from 'jwt-decode';

import api from '../api/caps';
import { hmac } from './hmac';
//import validators from '../services/validators';
import store from '../store';
import * as userActions from '../store/actions/userActions';

const tokenName = 'user_token';
const tokenState = 'user_state';
//const consts = window.config.consts;

/**
 * service interaction with user api
 *
 * @class UserService
 */
class UserService {
  constructor() {
    this.state = {};
  }

  isTokenExists() {
    return localStorage.getItem(tokenName) ? true : false;
  }

  isNeedChangePassword() {
    const state = this.getState();
    return this.isTokenExists() && state.required_reset;
  }

  getClientId() {
    if (!this.isTokenExists()) {
      return false;
    }
    const token = this.getToken();
    let out;
    try {
      out = jwt_decode(token);
    } catch (error) {
      return '';
    }
    return out.client_id;
  }

  getState() {
    let out = this.getToken(tokenState);
    try {
      out = JSON.parse(out);
    } catch (error) {
      //console.error(error, out);
      out = {};
      this.setState(out);
    }
    return out;
  }

  setState(state) {
    this.setToken(tokenState, JSON.stringify(state));
  }

  setToken(name, val) {
    localStorage.setItem(name, val);
    this.state[name] = val;
  }

  getToken(name = tokenName) {
    if (!this.state[name]) {
      this.state[name] = localStorage.getItem(name) ?? '';
      if (!this.state[name] || this.state[name] === 'undefined') {
        this.state[name] = '';
      }
    }
    return this.state[name];
  }

  /**
   * set user to local
   *
   * @param {*} token
   * @memberof User
   */
  setUser(result) {
    if (result.required_reset === undefined) {
      result.required_reset = false;
    }
    this.logined = true;
    this.state[tokenName] = result.token;
    this.state.admin = result.admin;
    localStorage.setItem(tokenName, result.token);
    localStorage.setItem(tokenState, JSON.stringify({ required_reset: result.required_reset, admin: result.admin }));
  }

  /**
   * unset user
   */
  unsetUser() {
    this.state[tokenName] = '';
    localStorage.removeItem(tokenName);
    localStorage.removeItem(tokenState);
  }

  /**
   * Check user token
   *
   * @memberof User
   */
  checkToken() {
    //console.log('🚀 ~ checkToken ');
    let logined = false;
    let required_reset = false;

    if (this.isTokenExists()) {
      // token exists - check for it valid
      //this.getUser();

      const state = this.getState();
      required_reset = state.required_reset;
      logined = required_reset !== undefined && !required_reset; // && state.required_reset !== undefined;
      this.state.admin = state.admin;
    }

    store.dispatch(userActions.checkUser(logined));
    this.logined = logined;
    if (!logined) {
      //if (!required_reset) {
      this.unsetUser();
      //}
    } //else {
    //const token = jwt_decode(this.getToken());
    //console.log(token);
    //}
  }

  /**
   * Login request
   * @param {*} username
   * @param {*} password
   * @returns promise
   */
  async login({ username, password }) {
    const result = await api.call('user.login', { username, password }); /*.catch((error) => {
      console.log('🚀 ~ login ~ error:', error);
      throw new Error(error.message());
    });*/
    // save token and data
    this.setUser(result);
    // save to store
    store.dispatch(userActions.setUser(result));
    return result;
  }

  /**
   * Login admin request
   * @param {*} username
   * @param {*} password
   * @returns promise
   */
  async loginAdmin({ username, password }) {
    const result = await api.call('admin.login', { username, password });
    result.required_reset = false;
    result.admin = true;
    //console.log('🚀 ~ loginAdmin ~ result', result);
    // save token and data
    this.setUser(result);
    // save to store
    store.dispatch(userActions.setUser(result));
    return result;
  }

  /**
   * logout
   */
  logout() {
    store.dispatch(userActions.unsetUser());
    window.continueLink = '';
    window.continueLinkAfterLogout = true;
    this.unsetUser();
  }

  /**
   * change password
   * @param {*} data
   * @returns
   */
  async changePassword(data) {
    const password = hmac(data.password);
    const result = await api.call('users.update_password', { password }).then((data) => {
      localStorage.setItem(tokenState, JSON.stringify({ required_reset: false }));
      return data;
    });

    return result;
  }

  /**
   * get dictionaries
   */
  async getDictionaries() {
    const result = await api.call('dictionaries.list', {});
    store.dispatch(userActions.setDictionaries(result));
  }

  /**
   * get user
   */
  async getUser() {
    const clientId = this.getClientId();
    if (!clientId) {
      this.logout();
      return;
      //store.dispatch(userActions.setClient(result))
      //throw new Error('Client id is not exists');
    }
    const result = await api.call('clients.view', { id: clientId }).catch((error) => {
      console.log('🚀 ~ result ~ error', error);
    });
    store.dispatch(userActions.setClient(result));
  }

  /**
   * get additional data from api after user login
   */
  getAdditionalInfo() {
    // dictionaries
    //this.getDictionaries();

    if (this.state.admin) {
      store.dispatch(userActions.setClient({ clientId: -1, admin: true }));
      return;
    }

    // user
    this.getUser();
  }

  isLogined() {
    return this.logined;
  }
}

export default new UserService();
