import axios from 'axios';

//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

class CapsApi {
  token = '';

  /**
   * set interceptors
   */
  constructor({ apiUrl, prepareRequest = null, prepareResponse = null, prepareError = null, timeout = 3000 }) {
    this.server = axios.create({
      baseURL: apiUrl,
      timeout: timeout,
      headers: {},
    });

    if (prepareRequest) {
      this.server.interceptors.request.use(prepareRequest, prepareError);
    }
    if (prepareResponse) {
      this.server.interceptors.response.use(prepareResponse, prepareError);
    }
  }

  async call(method, params) {
    return await this.server.post(method, params);
  }
}

export default CapsApi;
