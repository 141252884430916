import React from 'react';
import styled from 'styled-components';


const Content = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    background: rgba(255, 255, 255, .7);
    &:after{
        content: '';
        width: 31px;
        height: 31px;
        background: url('/images/loader.gif') no-repeat center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;
    }
`;

const Loader = () => {

    return (
        <Content />
    );
};


export default Loader;