export const breadcrumbsSet = values => ({type: 'BREADCRUMBS_SET', values: values});
export const breadcrumbsPush = values => ({type: 'BREADCRUMBS_PUSH', value: values});
export const breadcrumbsPop = () => ({type: 'BREADCRUMBS_POP'});
export const breadcrumbsChange = (id, value) => ({type: 'BREADCRUMBS_CHANGE', value: value, id: id});

export const setCounter = values => ({type: 'SET_COUNTER', counter: values.counter, counting: values.counting, var: values.var });
export const setLoading = values => ({type: 'SET_LOADING', loading: values});

export const setTmpEmail = values => ({type: 'SET_TMP_EMAIL', email: values});
export const setTmpPhone = values => ({type: 'SET_TMP_PHONE', phone: values});
