import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router';

import history from './services/history';

import Loader from './components/reusable/Loader';

// system
const NotFound = lazy(() => import('./components/errors/404'));
const ErrorPage = lazy(() => import('./components/errors/500'));
const VersionError = lazy(() => import('./components/errors/VersionError'));

// test
const Testing = lazy(() => import('./components/testing/Testing'));

// login registration restore
const Login = lazy(() => import('./components/login/Login'));
// orders
const Orders = lazy(() => import('./components/orders/Orders'));
const Order = lazy(() => import('./components/orders/Order'));
const OrderAdd = lazy(() => import('./components/orders/OrderAdd'));

// payments
const Payments = lazy(() => import('./components/payments/Payments'));

// admin
const LoginAdmin = lazy(() => import('./components/login/LoginAdmin'));
const OrdersAdmin = lazy(() => import('./components/orders/OrdersAdmin'));
const OrderAdmin = lazy(() => import('./components/orders/OrderAdmin'));
const PaymentsAdmin = lazy(() => import('./components/payments/PaymentsAdmin'));

// layouts
const Layout = lazy(() => import('./components/layout/Layout'));
const LayoutBackend = lazy(() => import('./components/layout/LayoutBackend'));

const PublicRoute = ({ component: Component, userLogined, ...rest }) => {
  const linkOld = window.continueLink;
  if (
    userLogined &&
    linkOld /* && (['/confirmEmail', '/restorepass', '/registration', '/prefill'].indexOf(rest.path) === -1)*/
  ) {
    window.continueLink = '';
    const nextUrl = linkOld;
    console.log(' >> ' + nextUrl);
    return <Redirect to={nextUrl} />;
  } else {
    console.log('public component at ' + rest.path);
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
};
PublicRoute.propTypes = {
  component: PropTypes.elementType,
  userLogined: PropTypes.bool.isRequired,
};

const PrivateRoute = ({ component: Component, userLogined, theme, admin, ...rest }) => {
  //console.log('🚀 ~ PrivateRoute ~ admin', admin);
  if (userLogined) {
    console.log('private component at ' + rest.path);
    if (theme) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout theme={theme}>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <LayoutBackend theme={theme}>
            <Component {...props} />
          </LayoutBackend>
        )}
      />
    );
  } else {
    console.log(' >> /login');
    if (!window.continueLinkAfterLogout) {
      window.continueLink = history.location;
    }
    window.continueLinkAfterLogout = false;
    const to = admin ? '/admin/login' : '/login';

    return <Redirect to={to} />;
  }
};
PrivateRoute.defaultProps = {
  admin: false,
};
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  userLogined: PropTypes.bool.isRequired,
  admin: PropTypes.bool,
  theme: PropTypes.string,
};

const AppRouter = (props) => {
  const { userLogined } = props;
  //console.log('render routes');
  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {/*public routes*/}
          <Route path="/versionerror" component={VersionError} />
          <Route path="/testing" component={Testing} />

          <PublicRoute exact path="/" component={Login} userLogined={userLogined} />
          <PublicRoute path="/login" component={Login} userLogined={userLogined} />

          <PublicRoute path="/admin/login" component={LoginAdmin} userLogined={userLogined} />

          <Route path="/error" render={(props) => <ErrorPage {...props} />} />

          {/*private routes*/}
          <PrivateRoute path="/error" component={ErrorPage} userLogined={userLogined} />

          <PrivateRoute path="/orders" component={Orders} userLogined={userLogined} />
          <PrivateRoute path="/orders-add" component={OrderAdd} userLogined={userLogined} />
          <PrivateRoute path="/order/:id" component={Order} userLogined={userLogined} />

          <PrivateRoute path="/payments" component={Payments} userLogined={userLogined} />

          {/*admin routes*/}
          <PrivateRoute path="/admin/orders" component={OrdersAdmin} userLogined={userLogined} admin />
          <PrivateRoute path="/admin/order/:id" component={OrderAdmin} userLogined={userLogined} admin />
          <PrivateRoute path="/admin/payments" component={PaymentsAdmin} userLogined={userLogined} admin />

          <PrivateRoute path="*" component={NotFound} userLogined={userLogined} />
        </Switch>
      </Suspense>
    </Router>
  );
};

AppRouter.propTypes = {
  userLogined: PropTypes.bool.isRequired,
};

AppRouter.defaultProps = {
  userLogined: false,
};

export default AppRouter;
