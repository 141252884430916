const commonReducer = function (state, action) {
  if (!state) {
    state = { breadcrumbs: [], loading: true };
  }

  let breadcrumbs;

  switch (action.type) {
    case 'BREADCRUMBS_SET':
      //console.log(action.values);
      return Object.assign({}, state, { breadcrumbs: action.values });
    case 'BREADCRUMBS_PUSH':
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs.push(action.value);
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'BREADCRUMBS_POP':
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs.pop();
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'BREADCRUMBS_CHANGE':
      //console.log(action.values);
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs[action.id] = Object.assign({}, breadcrumbs[action.id], action.value);
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'SET_LOADING':
      return Object.assign({}, state, { loading: action.loading });
    default:
      return state;
  }
};

export default commonReducer;
