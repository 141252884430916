const defaultState = { userChecked: false, userLogined: false, user: {}, dictionaries: null, client: null };

const userReducer = function (state = defaultState, action) {
  switch (action.type) {
    case 'USER_CHECKED':
      return Object.assign({}, state, { userChecked: action.value, userLogined: action.result });

    case 'SET_USER':
      return Object.assign({}, state, {
        userChecked: true,
        user: action.user,
        userLogined: true,
      });

    case 'UNSET_USER':
      return Object.assign({}, state, { user: {}, userLogined: false });

    case 'SET_DICTIONARIES':
      return Object.assign({}, state, { dictionaries: action.value });

    case 'SET_CLIENT':
      return Object.assign({}, state, { client: action.value });

    default:
      return state;
  }
};

export default userReducer;
