import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
/*
history.listen(location => {
    console.log('history change', location);
    //window.BSTR_ROUTING = false;
});
*/

export default history;